<template class="bg">
  <div class="login">
    <h2>民法典管理系统</h2>
    <a-form-model :model="form" @submit="handleSubmit" @submit.native.prevent>
      <a-form-model-item>
        <a-input ref="account" size="large" v-model="form.account" placeholder="请输入您的帐号">
          <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-input size="large" v-model="form.password" type="password" placeholder="请输入您的密码">
          <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-button size="large" type="primary" html-type="submit">
          立即登录
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          account: '',
          password: '',
        },
      };
    },
    mounted() {
      if (this.$route.query.from == 'kicked') {
        this.$message.error('帐号已在其它地方登录，请重新登录！')
      }
    },
    methods: {
      handleSubmit(e) {

        if (!this.form.account || !this.form.password) {
          this.$toast('请输入您的帐号和密码');
          this.$refs.account.focus();
          return false
        }

        this.form.domain = window.baseKey;

        this.$post('/login', this.form, { overlay: true }).then( res => {
          let json = res.data;
          if (json.code == 0) {
            this.$store.commit('signin', json.data.staff);
            this.$store.commit('menu', json.data.menus);

            if (this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push({ name:'Index' });
            }
          }
        })

      },
    },
  };
</script>

<style lang="less" scoped>
  .bg {
      background-color: #ddedf3;
  }
  .login {
    margin: 120px auto 0 auto; width: 420px;
    padding: 32px 40px 32px 40px; background: #fff;
    border-radius: 8px;
  }
  .login h2 {
    font-size: 18px; text-align: center;
    margin-bottom: 40px;
  }
  .login button {
    margin-top: 24px;
    width: 100%;
  }

  .login /deep/ .ant-form-item {
    margin-top: 24px;
  }
</style>